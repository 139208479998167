<template>
  <ConnectWalletBase url="prepare">
    <template v-slot:content>
      <div class="text-lg text-center">BulkSender</div>
      <div class="text-caption text-caption my-4">
        Wellcome to BulkSender! Here you can send tokens to a list of multiple addresses at the same time quickly and
        efficiently with 4 operations:
      </div>
      <div class="text-caption mb-9">
        <ul>
          <li>Prepare: Supply Data for bulk send</li>
          <li>Approve: Send Approve transaction</li>
          <li>Confirm: Confirm bulksending data, include txs, fees</li>
          <li>Send: Confirm transaction in your wallet and Wait for complete</li>
        </ul>
      </div>
    </template>
  </ConnectWalletBase>
</template>
<script>
import ConnectWalletBase from '../ConnectWalletBase.vue'
export default {
  props: {
    url: String,
  },
  components: {
    ConnectWalletBase,
  },
  data() {
    return {}
  },
}
</script>